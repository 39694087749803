.root {
}

.media {
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
